@import '../theme-bootstrap';
@import '../../../../../all/themes/elc_base/scss/standalone/shared/animations';

// Shimmering Skeleton Full.
.elc-skeleton-region {
  &--left-column {
    position: relative;
    display: inline-flex;
    width: 100%;
    max-width: 800px;
    @media #{$medium-up} {
      float: $ldirection;
      width: 57%;
      margin-bottom: 20px;
    }
  }
  &--right-column {
    padding: 26px 0 0;
    display: flex;
    flex-wrap: wrap;
    color: $color-navy;
    @media #{$medium-up} {
      @include swap_direction(padding, 26px 20px 0 33px);
      float: $rdirection;
      max-width: 600px;
      width: 40%;
    }
    .elc-shade-description-inline {
      line-height: 16px;
      letter-spacing: 0.1px;
    }
  }
  &--info-content {
    max-width: 1040px;
    position: relative;
    margin: 0 auto;
    .sd-renutriv-spp-formatter & {
      font-family: $renutriv-roboto;
      .elc-product-attribute-header {
        font-family: $renutriv-roboto;
        font-size: 15px;
      }
      .elc-product-attribute-content {
        font-family: $renutriv-roboto;
      }
    }
  }
}
.elc-skeleton-feature {
  &--thumbCarousel {
    display: none;
    height: unset;
    @media #{$medium-up} {
      display: block;
      position: relative;
      width: 68px;
      height: 100%;
      max-height: 514px;
      @include swap_direction(margin, 45px 0 0 0);
    }
    .slider-view {
      box-sizing: border-box;
      width: 100%;
      position: relative;
      display: block;
    }
    .slick-slider .slick-slide {
      width: 68px;
    }
    .elc-img {
      cursor: pointer;
      height: 60px;
      width: 60px;
      margin-bottom: 17px;
      margin-#{$ldirection}: 4px;
    }
    .elc-product-images-wrapper {
      height: 100%;
      &:nth-child(1) .elc-img {
        border: 1px solid $color-black;
        margin-top: 4px;
      }
    }
    .elc-directional-icon {
      visibility: hidden;
    }
  }
  &--carousel {
    position: relative;
    width: 100%;
    max-width: 620px;
    height: 100%;
    max-height: 600px;
    margin: 8px auto 0;
    margin-#{$rdirection}: initial;
    @media #{$medium-up} {
      padding: 0 10px;
      @include swap_direction(margin, 0 0 0 auto);
    }
    img {
      width: 100%;
      float: $rdirection;
      height: auto;
      @media #{$medium-up} {
        width: 600px;
      }
    }
  }
  &--displayName {
    h1,
    .sub-header {
      font-family: $optimaregular;
      font-size: 21px;
      letter-spacing: 0.4px;
      line-height: 1;
      margin: 6px 0 13px;
      font-weight: normal;
      padding: 0 30px;
      @media #{$medium-up} {
        font-size: 32px;
        padding: unset;
      }
      // ReNutriv.
      .sd-renutriv-spp-formatter & {
        font-size: 24px;
        line-height: get-line-height(24px, 30px);
        letter-spacing: 0;
        color: $color-black;
        font-family: $renutriv-bodoni;
        text-transform: uppercase;
        @media #{$medium-up} {
          line-height: get-line-height(24px, 28px);
        }
      }
      // Aerin.
      .sd-aerin-spp-formatter & {
        @include headline--quinary;
        font-family: $futura_now_reg;
        color: $color-periwinkle-blue--aerin-v2;
        position: relative;
        z-index: 1;
        margin: 0;
        margin-bottom: 5px;
        font-size: 32px;
        letter-spacing: 0.4px;
        line-height: 1;
        &:hover {
          text-decoration: none;
        }
        @media #{$medium-up} {
          font-size: 28px;
          margin: 0;
        }
      }
    }
  }
  &--ratingAndReviews {
    margin: 5px 0 12px;
    padding: 0 30px;
    width: 100%;
    font-weight: 700;
    @media #{$medium-up} {
      padding: 0;
      margin: 0 0 10px;
    }
    .elc-stars-wrapper {
      display: inline-flex;
      align-items: center;
      margin-#{$rdirection}: 10px;
    }
    .elc-stars-simplified {
      top: 2px;
      &-stars {
        top: 0;
        &::before {
          position: relative;
          top: 0;
        }
      }
    }
    .elc-reviews-number {
      letter-spacing: 0.05em;
      margin-#{$ldirection}: 2px;
      line-height: 1.4;
      // Aerin.
      .sd-aerin-spp-formatter & {
        position: relative;
        top: 3px;
      }
    }
    .elc-read-reviews {
      display: inline-block;
      padding: 0;
      position: static;
      opacity: 1;
      .sd-renutriv-spp-formatter & {
        font-family: $renutriv-roboto;
        font-weight: 300;
        text-decoration: underline;
        letter-spacing: 0;
      }
    }
    .elc-product-reviews-link {
      .sd-renutriv-spp-formatter & {
        font-family: $renutriv-roboto;
        font-weight: 300;
        text-decoration: underline;
        letter-spacing: 0;
      }
      font-size: 14px;
      text-decoration: underline;
    }
  }
  &--shortDescription {
    font-family: $akzidenz;
    font-size: 15px;
    letter-spacing: 0.3px;
    display: inline-block;
    margin: 0 30px 10px;
    width: 100%;
    @media #{$medium-up} {
      margin: 2px 0 15px;
    }
    // ReNutriv.
    .sd-renutriv-spp-formatter & {
      font-size: 16px;
      line-height: get-line-height(16px, 24px);
      padding-top: 10px;
      font-family: $renutriv-roboto;
      letter-spacing: 0.02em;
      font-weight: 300;
      color: $color-black;
      @media #{$medium-up} {
        font-size: 15px;
        line-height: normal;
      }
    }
    // Aerin.
    .sd-aerin-spp-formatter & {
      line-height: 18px;
      color: $color-periwinkle-blue--aerin-v2;
      margin-bottom: 9px;
      font-family: $futura_now_md;
      font-size: 15px;
      letter-spacing: 0.3px;
      @media #{$medium-up} {
        margin-bottom: 0;
      }
    }
  }
  &--sizePicker {
    padding-inline-end: 20px;
    .multiple-sizes & {
      width: 100%;
      margin-bottom: 10px;
    }
    &:empty {
      display: none;
      & + .elc-skeleton-feature--price {
        width: 100%;
      }
    }
    &:has(> .single-size) {
      & + .elc-skeleton-feature--price {
        .elc-product-prices-clickable-wrapper {
          display: flex;
          flex-direction: column;
          padding: 0;
          .elc-product-price-per-unit-row-wrapper {
            p {
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  &--price {
    width: auto;
    display: inline-block;
    margin-bottom: 10px;
    .discounted {
      text-decoration: line-through;
    }
    .elc-product-prices-clickable-wrapper,
    .elc-product-prices-wrapper {
      width: auto;
      display: flex;
      flex-direction: column;
      h4 {
        padding-bottom: 0;
      }
      .elc-product-tax-included-label-wrapper {
        font-size: 16px;
      }
      &:has(.elc-product-tax-included-label-wrapper) {
        flex-direction: row;
      }
      .elc-product-price {
        padding: 0;
      }
      .elc-price-formatted {
        .sd-renutriv-spp-formatter & {
          font-family: $renutriv-roboto;
        }
      }
    }
    .elc-product-price-per-unit-row-wrapper {
      padding: 0 15px;
      p {
        .sd-renutriv-spp-formatter & {
          font-family: $renutriv-roboto;
        }
        margin-top: 0;
        margin-bottom: 0;
      }
      @media #{$medium-up} {
        padding: 0;
      }
    }
  }
  &--autoReplenish {
    &:empty {
      margin: 0;
    }
    background: $color-white;
    width: calc(100% - 60px);
    @media #{$medium-up} {
      background: $color-white;
      margin: 0 0 10px;
      width: 100%;
    }
    .elc-auto-replenish-subscribe-wrapper {
      align-items: center;
      .sd-renutriv-spp-formatter &,
      .sd-renutriv-spp-formatter & p {
        font-family: $renutriv-roboto;
      }
      .elc-body--2 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .elc-auto-replenish-subscribe-copy-wrapper {
      .sd-renutriv-spp-formatter & {
        font-family: $renutriv-roboto;
      }
    }

    .sd-switch {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .elc-switch {
        cursor: not-allowed;
        background-color: $color-dark-blue;
        height: 20px;
        width: 40px;
        border-radius: 10px;
        padding: 1px;
        .elc-switch-circle {
          border-radius: 50%;
          box-shadow: $color-light-gray-dove 0 0 3px;
          background-color: $color-white;
          height: 18px;
          width: 18px;
        }
      }
    }
  }
  &--quantitySelector {
    cursor: not-allowed;
    background: $color-white;
    @media #{$medium-up} {
      background: $color-white;
      margin-#{$rdirection}: 0;
      float: $ldirection;
    }
    @media only screen and (min-width: 737px) and (max-width: 1166px) {
      width: 100%;
    }
    .elc-quantity-selector-label {
      line-height: 16px;
      .sd-renutriv-spp-formatter & {
        font-family: $renutriv-roboto;
      }
    }
    .elc-spinner-selector {
      cursor: not-allowed;
      display: inline-flex;
      .elc-spinner-selector-data-wrapper {
        flex-grow: 2;
        .sd-renutriv-spp-formatter & {
          border-right: 1px solid;
          border-left: 1px solid;
        }
      }
      .sd-renutriv-spp-formatter & {
        border: 1px solid $color-black;
      }
    }
    .elc-spinner-selector-button {
      border: none;
      background: $color-white;
      .elc-icon {
        padding: 1px 6px;
        height: 13px;
        width: 13px;
        background: $color-black;
      }
      .elc-minus-icon {
        @include svg-icon-inline-mask('minus.svg');
      }
      .elc-plus-icon {
        @include svg-icon-inline-mask('plus.svg');
      }
    }
  }
  &--addToBag {
    color: $color-med-dark-gray;
    background: $color-med-light-gray;
    border-radius: unset;
    font-family: $akzidenz;
    font-size: 12px;
    font-weight: bold;
    padding: 0;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    line-height: 3.4;
    display: none;
    @media #{$medium-up} {
      height: 40px;
      width: 133px;
      display: inline-block;
      margin-#{$ldirection}: 15px;
    }
    @media only screen and (min-width: 737px) and (max-width: 1166px) {
      margin-left: 0;
      margin-top: 16px;
    }
    &:hover {
      color: $color-med-dark-gray;
      background: $color-med-light-gray;
    }
    // ReNutriv.
    .sd-renutriv-spp-formatter & {
      outline: 1px solid $color-gold--renutriv-v2;
      font-size: 22px;
      font-family: $renutriv-roboto;
      background: $color-black;
      color: $color-med-dark-gray;
      @media #{$medium-up} {
        font-size: 12px;
      }
    }
    // Aerin.
    .sd-aerin-spp-formatter & {
      font-family: $futura_now_md;
      font-weight: 500;
      &:hover {
        background: $color-periwinkle-blue--aerin-v2;
      }
    }
  }
  &--installmentsPrice {
    @include shimmer(1.5s, ease-in-out);
    padding: 0 30px 15px;
    display: inline-block;
    position: relative;
    width: calc(100% - 60px);
    margin: 10px 30px;
    @media #{$medium-up} {
      margin: 15px 0 12px;
      width: 100%;
    }
  }
  &--socialShare {
    @include swap_direction(margin, 8px 0 12px 30px);
    height: 15px;
    background: $color-white;
    .elc-social-share {
      width: auto;
    }
    @media #{$medium-up} {
      margin: 8px 0 12px;
    }
    .sd-renutriv-spp-formatter & {
      font-family: $renutriv-roboto;
      font-size: 15px;
    }
  }
  &--shadePickerComponent {
    .multiple-shades & {
      width: 100%;
      padding: 0;
      margin-bottom: 0;
      display: grid;
      @media #{$medium-up} {
        width: 100%;
        padding-right: 15px;
        display: block;
      }
    }
    .elc-product-shade-groups {
      border-bottom: 1px solid $color-light-gray;
      padding-inline-start: 5px;
      display: block;
      height: fit-content;
      margin: 11px 0 8px;
      @media #{$medium-up} {
        width: 80%;
      }
      .elc-product-shade-group-link {
        margin-inline-end: 25px;
        display: inline-block;
      }
    }
    .elc-product-shade-picker {
      margin-bottom: 6px;
      padding: 0 30px;
      overflow-y: auto;
      width: 100%;
      @media #{$medium-up} {
        padding-#{$ldirection}: 0;
      }
      .elc-slider-view-wrapper span:first-of-type {
        font-weight: bold;
      }
      .elc-slider-view-wrapper span {
        letter-spacing: normal;
      }
      .elc-product-shades-container {
        padding-inline-start: 0;
        overflow-y: auto;
        max-height: 200px;
      }
      .elc-product-shades-grid-wrapper {
        display: flex;
        max-width: 100%;
        overflow-x: hidden;
        @media #{$medium-up} {
          display: flex;
          flex-wrap: wrap;
          margin-inline-end: 30px;
          max-height: 110px;
        }
        > div {
          display: inline-flex;
        }
      }
      .elc-shade-image {
        width: 30px;
        height: 30px;
        padding: 2px;
        margin: 5px 3px 3px;
        position: relative;
        text-align: center;
        background-color: transparent;
        border-radius: 50%;
        box-sizing: content-box;
        display: inline-block;
        border: 1px solid transparent;
        @media #{$medium-up} {
          width: 22px;
          height: 22px;
        }
        > div {
          position: relative;
          border-radius: 50%;
        }
        svg:not(:root) {
          overflow: hidden;
        }
      }
      .elc-shade-image-wrapper:nth-child(1) {
        .elc-shade-image {
          border-color: $extended_mpp_black_pearl;
        }
      }
    }
  }
  &--productAttributes {
    padding: 26px 20px 0 60px;
    float: $rdirection;
    max-width: 600px;
    width: 100%;
    @media #{$medium-up} {
      display: block;
      width: 40%;
    }
  }
  &--promoMessage {
    width: 100%;
    .elc-product-promo-message p {
      .sd-renutriv-spp-formatter & {
        font-family: $renutriv-roboto;
        text-transform: uppercase;
      }
      .sd-aerin-spp-formatter & {
        font-family: $futura_now_reg;
        color: $color-periwinkle-blue--aerin-v2;
      }
    }
  }
}

// Dynamic Skeleton Full.
.skeleton-full {
  .sd-full-skeleton & {
    @media #{$medium-up} {
      padding-top: 13px;
      display: inline-block;
      box-sizing: border-box;
    }
  }
  // Left Column - Carousel.
  .elc-carousel-section-wrapper .slick-slider {
    position: relative;
    box-sizing: border-box;
    user-select: none;
  }
  .elc-carousel-section-wrapper .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    min-height: 1px;
    min-width: 1px;
  }
  .elc-carousel-section-wrapper .slick-slider .slick-track {
    min-height: 1px;
    min-width: 1px;
    &::before,
    &::after {
      content: '';
      display: table;
    }
  }
  .elc-carousel-section-wrapper .slick-slider .slick-slide {
    display: block;
    height: auto;
    background-color: transparent;
    border: none;
    padding-bottom: 6px;
    float: $ldirection;
    min-height: 1px;
  }
  // Left Column - Carousel Products.
  .elc-carousel-products {
    position: relative;
    margin: 0 auto;
    @media #{$medium-up} {
      @include swap_direction(margin, 0 -9px 0 auto);
    }
    .slick-slide {
      width: 600px;
    }
    .elc-img {
      width: 100%;
      height: auto;
      @media #{$medium-up} {
        width: 600px;
        height: 600px;
      }
    }
  }
  // Right Column - Layout.
  .elc-right-column-section {
    padding-top: 42px;
    @media #{$medium-up} {
      padding-top: 26px;
    }
  }
  // Right Column - Display Name.
  .elc-product-display-name-wrapper {
    margin-bottom: 13px;
  }
  // Right Column - Short Description
  .elc-short-description-container {
    line-height: 1.1;
    & + .elc-size-picker-container.multiple-sizes {
      margin-top: 14px;
    }
  }
  .elc-product-short-description-wrapper {
    margin: 0;
    padding: 0 30px;
    @media #{$medium-up} {
      margin: 0 0 1px;
      padding: 0;
    }
  }
  // Right Column - Size & Shade Pickers.
  .elc-size-picker-container {
    margin-bottom: 0;
    // Single Size.
    &.single-size {
      @include swap_direction(padding, 0 0 0 30px);
      @media #{$medium-up} {
        padding: 0;
      }
      .elc-size-picker-text {
        line-height: 0.9;
        margin: 0;
        white-space: nowrap;
        // ReNutriv.
        .sd-renutriv-spp-formatter & {
          font-weight: 300;
          letter-spacing: 0.02em;
          color: $color-black;
          font-family: $renutriv-roboto;
        }
        // Aerin.
        .sd-aerin-spp-formatter & {
          font-family: $futura_now_reg;
          color: $color-periwinkle-blue--aerin-v2;
        }
      }
    }
    &.multiple-sizes {
      margin-top: 15px;
      // Aerin.
      .sd-aerin-spp-formatter & {
        margin-top: 0;
        @media #{$medium-up} {
          margin-top: 15px;
        }
      }
      .elc-size-picker-wrapper {
        padding-#{$ldirection}: 0;
        > .elc-dropdown-wrapper {
          padding: 0 30px;
          @media #{$medium-up} {
            padding: 0;
          }
        }
      }
      & + .elc-product-prices-wrapper {
        margin-top: 12px;
      }
    }
  }
  // Dropdowns.
  .elc-dropdown-wrapper {
    display: inline-block;
    margin: 0;
    vertical-align: top;
    width: 100%;
    cursor: not-allowed;
    .elc-dropdown {
      .elc-dropdown-wrapper {
        cursor: not-allowed;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        padding-#{$ldirection}: 8px;
        position: relative;
        border-radius: 2px;
      }
      .elc-dropdown-readonly-input {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        flex: 1 1 0%;
        overflow: hidden;
        padding: 0 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        .sd-renutriv-spp-formatter &  {
          font-family: $renutriv-roboto;
        }
      }
      .elc-dropdown-arrow {
        cursor: not-allowed;
        height: 40px;
        padding: 0 16px;
        background: $color-white;
      }
      .elc-arrow-icon {
        @include svg-icon-inline-mask('dropdown.svg');
        cursor: not-allowed;
        width: 10px;
        height: 10px;
        background-color: $color-black;
        transform: scale(0.9, 0.6);
      }
    }
  }
  // Shade Dropdown.
  .elc-shades-dropdown-component {
    margin: 0;
    padding: 0 30px;
    @media #{$medium-up} {
      margin: 15px 0;
      padding: 0;
    }
  }
  .elc-product-shade-picker-dropdown {
    margin-bottom: 8px;
  }
  // Info Section.
  .elc-product-overview {
    width: 100%;
    float: unset;
    padding-#{$ldirection}: 0;
    @media #{$medium-up} {
      width: 55%;
      float: $ldirection;
    }
    .elc-product-full-accordion {
      .elc-header-label {
        // ReNutriv.
        .sd-renutriv-spp-formatter & {
          font-size: 24px;
          line-height: get-line-height(24px, 30px);
          font-weight: 400;
          letter-spacing: 0;
          font-family: $renutriv-bodoni;
          text-transform: uppercase;
          color: $color-black;
          @media #{$medium-up} {
            line-height: 28px;
            font-family: $renutriv-bodoni;
          }
        }
        // Aerin.
        .sd-aerin-spp-formatter & {
          font-family: $futura_now_reg;
          font-size: 20px;
          color: $color-periwinkle-blue--aerin-v2;
          @media #{$medium-up} {
            font-size: 28px;
          }
        }
      }
      .elc-label-slide-content,
      .elc-label-slide-content p {
        @media #{$medium-up} {
          padding-top: 14px;
          letter-spacing: 0.015em;
        }
        // ReNutriv.
        .sd-renutriv-spp-formatter & {
          font-family: $renutriv-roboto;
          font-size: 15px;
        }
        // Aerin.
        .sd-aerin-spp-formatter & {
          font-family: $futura_now_reg;
          color: $color-periwinkle-blue--aerin-v2;
        }
      }
    }
  }
  .elc-info-content-section {
    padding: 26px 0 0;
    position: relative;
    width: 100%;
    @media #{$medium-up} {
      top: -25px;
    }
  }
  &.multiple-shades {
    .elc-skeleton-feature--sizePicker {
      .elc-size-picker-container.single-size {
        margin-top: 0;
        width: auto;
      }
    }
  }
}
